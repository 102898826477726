<template>
  <section class="product d-flex flex-column justify-content-center">
    <div class="main">
      <div class="container">
        <div class="wrapper">
          <div class="products__image">
            <ul class="breadcrumb">
              <li>
                <router-link to="/">Главная /</router-link>
              </li>
              <li>
                <router-link to="/products">Продукция /</router-link>
              </li>
              <li>
                {{ product.name }}
              </li>
            </ul>
            <ProductGallery :image="product.image" :images="product.images" />
          </div>
          <div class="right__cnt">
            <p>Артикул: {{ product.sku }}</p>
            <h2 class="my-4">{{ product.name }}</h2>
            <h4>
              {{ product.meta_description }}
            </h4>
            <div class="right__price my-4">
              <span :class="[{old: product.special}]">{{ product.price_formated }}</span>
              <span class="special" v-if="product.special">{{ product.special_formated }}</span>
            </div>

            <div class="natural right__basket">
              <h5 v-if="getCountInPakage">Объем: {{ getCountInPakage }} шт.</h5>
              <div class="calc">
                <h5>Количество:</h5>
                <span class="bordered" @click="decrementCount"> - </span>
                <div>{{ count }}</div>
                <span class="bordered" @click="incrementCount"> + </span>
              </div>
              <div class="stock-status">
                <p
                  :style="{
                    color: [
                      isInStock
                        ? 'red'
                        : '#49C50E',
                    ],
                  }"
                >
                  {{ product.stock_status }}
                </p>
              </div>
              <div class="property__image my-5">
                <img src="@/assets/img/prod_prop1.svg" />
                <img class="mx-4" src="@/assets/img/prod_prop2.svg" />
                <img src="@/assets/img/prod_prop3.svg" />
              </div>
              <div class="order">
                <button class="order__add" @click="addToCart">
                  Добавить в корзину
                </button>
                <button
                  class="order__wish"
                  :class="{ animation: addedToWish }"
                  @click="addToWish(product.id)"
                  :disabled="!isAuthenticated"
                >
                  <img src="@/assets/img/likes.svg" alt="" />
                </button>
                <button class="order__fast" @click="sendToWhatsUp">
                  Получить консультацию специалиста
                </button>
                <a :href="product.location" class="buy-credit">
                  Купить в кредит
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="basket">
      <div class="container">
        <div class="basket__body">
          <div class="basket__about">
            <h2>Описание:</h2>
            <div v-html="product.description"></div>
          </div>
          <div class="tabs-block">
            <div class="tabs">
              <input
                type="radio"
                name="tab-btn"
                id="tab-btn-1"
                value=""
                checked
              />
              <label for="tab-btn-1">Состав:</label>
              <input type="radio" name="tab-btn" id="tab-btn-2" value="" />
              <label for="tab-btn-2">Применение:</label>
              <input type="radio" name="tab-btn" id="tab-btn-3" value="" />
              <label for="tab-btn-3">Отзывы ({{ totalReviews }})</label>

              <div id="content-1" v-html="product.description_sostav"></div>
              <div id="content-2" v-html="product.description_priminen"></div>
              <div
                id="content-3"
                v-for="review in getReviews"
                :key="review.author"
              >
                <h4>{{ review.author }}</h4>
                <p v-html="review.text"></p>
              </div>
            </div>
            <div
              class="sertf"
              v-if="
                (product.sertificate && product.sertificate.length) ||
                (product.instruction && product.instruction.length)
              "
            >
              <p>Инструкции и сертификаты:</p>
              <img
                v-for="(certificate, idx) in product.sertificate"
                :key="idx + 10"
                :src="certificate"
                @click="showSertificate"
                alt="sertificate"
              />

              <a
                v-for="(manual, idx) in product.instruction"
                :key="idx"
                :href="manual"
                target="_blank"
              >
                <div class="instruction">
                  <img
                    src="../../assets/img/pdf.svg"
                    width="80"
                    height="80"
                    alt="instruction.pdf"
                  />
                  <p>Инструкция.pdf</p>
                </div>
              </a>
              <!-- <img src="@/assets/img/sertificate.svg" @click="showImage = true" alt="sertificate" /> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="recommendation"
      v-if="relatedProducts && relatedProducts.length"
    >
      <div class="container">
        <h2 class="recommendation__title">ВАМ ТАКЖЕ МОЖЕТ ПОНРАВИТЬСЯ</h2>
        <div class="recommendation__body">
          <Carousel related :products="relatedProducts" />
        </div>
      </div>
    </section>
    <ImageCard :visible="showImage" @close="exit('image')" />
    <OneClickModal :visible="showOneClick" @close="exit" :product="product" />
  </section>
</template>

<script>
import Carousel from "@/components/ui/Carousel.vue";
import { mapGetters, mapActions } from "vuex";
import addToCart from '@/mixins/addToCart.js';

export default {
  props: ["id"],
  data() {
    return {
      product: {},
      showImage: false,
      errorMessage: null,
      showOneClick: false,
      addedToWish: false,
      count: 1,
    };
  },
  components: {
    ImageCard: () => import("@/components/ui/ImageCard.vue"),
    OneClickModal: () => import("@/components/shop/OneClickModal.vue"),
    Carousel,
    // SimilarProducts: () => import("@/components/product/SimilarPrtoducts.vue"),
    // Test component which inform about error, and what kind error
    // ModalMessage: () => import("@/components/ui/ModalMessage.vue"),
    ProductGallery: () => import("@/components/product/ProductGallery.vue"),
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("products", ["relatedProducts"]),

    totalReviews() {
      if (this.product.length) {
        return this.product.reviews.review_total;
      }
      return 0;
    },
    getReviews() {
      return this.product.length && this.product.reviews.reviews;
    },
    getCountInPakage() {
      return this.product.length && parseInt(this.product.weight);
    },
  },
  methods: {
    ...mapActions("wishlist", ["addToWishList"]),
    ...mapActions("products", ["getRelatedProducts"]),

    // This method was created for the test of modal report an error

    // async addToWish(id) {
    //   try{
    //     await this.addToWishList(id)
    //   }
    //   catch(err) {
    //     const { data: {error} } = err.response;
    //     this.errorMessage = error[0] || 'An error occurred';
    //   }
    // },

    showSertificate() {
      this.showImage = true;
      document.body.style.overflow = "hidden";
    },
    exit(cond) {
      document.body.style.overflow = "visible";
      if (cond === "image") {
        this.showImage = false;
      } else {
        this.showOneClick = false;
      }
    },

    addToWish(id) {
      this.addedToWish = !this.addedToWish;
      this.addToWishList(id);
      setTimeout(() => (this.addedToWish = false), 500);
    },

    incrementCount() {
      this.count += 1;
      this.product.status = this.count;
    },
    decrementCount() {
      if (this.count > 1) {
        this.count -= 1;
        this.product.status = this.count;
      }
    },

    handleError() {
      this.errorMessage = null;
    },

    async getProductById(id) {
      try {
        const responseId = await this.$axios(
          `https://back.rizaherb.kz/index.php?route=feed/rest_api/products&id=${id}`
        );
        const { data } = responseId.data;
        this.product = { ...data };
        // console.log(this.product);
      } catch (err) {
        if (err) {
          // If id is haven`t in data, redirect on not exist page
          this.$router.replace("/product-is-not-exist");
        }
      }
    },
    sendToWhatsUp() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=7750634885&text&app_absent=0";
    },
  },

  // This watcher react for changes of id, when we push on related products
  watch: {
    async id(val) {
      const id = val.includes("_") ? val.split("_")[1] : val;
      const responseVal = await this.$axios(
        `https://back.rizaherb.kz/index.php?route=feed/rest_api/products&id=${id}`
      );
      const { data } = responseVal.data;
      this.product = { ...data };
    },
  },

  mixins: [addToCart],

  created() {
    const id = this.id.includes("_") ? this.id.split("_")[1] : this.id;
    this.getProductById(id);
    this.getRelatedProducts(id);
  },
};
</script>

<style scoped>
.product {
  font-family: "Exo 2", sans-serif;
  padding-bottom: 100px;
}
.main {
  width: 100%;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 10px;
}
.breadcrumb {
  display: flex;
}
.breadcrumb li {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}
.breadcrumb li a:hover {
  opacity: 45%;
}
.main .wrapper {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: nowrap;
  justify-content: space-between; */
}
.products__image {
  flex: 1 1 40%;
}

.main .wrapper .right__cnt {
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  max-width: 400px;
}
.main .wrapper .right__cnt h2 {
  font-family: "Exo 2", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.35em;
  text-transform: uppercase;

  color: #000000;
}
.main .wrapper .right__cnt h4 {
  font-family: "Exo 2", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  max-width: 300px;
  color: #606060;
}
.main .wrapper .right__cnt h5 {
  font-family: "Exo 2", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}
.main .wrapper .right__cnt .bordered {
  font-family: "Exo 2", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 4px 5px;
  margin: auto 10px;
  cursor: pointer;
  /* identical to box height */

  color: #606060;
}
.main .wrapper .right__cnt p {
  font-family: "Exo 2", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;

  color: #7a7a7a;
}
.main .wrapper .right__cnt .right__price {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}
.main .wrapper .right__cnt .right__price span {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;

  color: #000000;
}
.main .wrapper .right__cnt span.special {
  color: red
}

/* Buttons for order */

.main .wrapper .right__cnt .right__basket {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.main .wrapper .right__cnt .right__basket .order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* min-width: 380px; */
}

.main .wrapper .right__cnt .right__basket .order__add {
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  flex: 1 1 75%;
  height: 70px;
  color: #ffffff;
  background: #75b051;
  border-radius: 5px;
  border: 1px solid #75b051;
}

.main .wrapper .right__cnt .right__basket .order .order__wish {
  flex: 1 1 20%;
  height: 70px;
  background-color: #75b051;
  margin-left: 5px;
  border-radius: 5px;
}

.main .wrapper .right__cnt .right__basket .order .order__wish img {
  width: 30%;
}

.main .wrapper .right__cnt .right__basket .order .order__fast {
  font-weight: normal;
  padding: 15px 25px;
  background-color: #75b051;
  border: 1px solid #75b051;
  border-radius: 5px;
  flex: 1 1 auto;
  color: #ffffff;
  margin-top: 5px;
  font-size: 19px;
  line-height: 16px;
}
.main .wrapper .right__cnt .right__basket .order .buy-credit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  color: #fff;
  background-color: #ef4635;
  border: 1px solid #ef4635;
  border-radius: 5px;
  flex: 1 1 auto;
  height: 30px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
}
.main .wrapper .right__cnt .right__basket .order .buy-credit::before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("../../assets/img/kaspi-red.svg") no-repeat;
  position: absolute;
  left: 35px;
}

.main .wrapper .right__cnt .right__basket button:hover,
.main .wrapper .right__cnt .right__basket a:hover,
.main .wrapper .right__cnt .right__basket .order .order__fast:hover {
  background: #fff;
  color: #75b051;
}

.main .wrapper .right__cnt .right__basket .order .order__wish:hover {
  background-color: #a3d882;
}
.main .wrapper .right__cnt .property__image img {
  width: 55px;
}

.main .wrapper .right__cnt .calc {
  display: flex;
  align-items: center;
  margin: 0;
}
.main .wrapper .right__cnt .calc div {
  font-size: 18px;
  width: 20px;
  text-align: center;
}

/* Tabs */
.basket {
  width: 100%;
}
.basket .tabs {
  font-size: 0;
  width: 100%;
  max-width: 400px;
}
.basket .tabs > input[type="radio"] {
  display: none;
}
.basket .tabs > div {
  display: none;
  border-top: 1px solid #7a7a7a;
  padding: 30px 0;
  font-size: 16px;
  font-family: "Exo 2" !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.basket #tab-btn-1:checked ~ #content-1,
.basket #tab-btn-2:checked ~ #content-2,
.basket #tab-btn-3:checked ~ #content-3 {
  display: block;
}
.basket .tabs > label {
  border: 1px solid transparent;
  padding: 3px 8px;
  font-size: 16px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
}
.basket .tabs > label:not(:first-of-type) {
  border-left: none;
}
.basket .tabs > input[type="radio"]:checked + label {
  background-color: #fff;
  border: transparent;
  border-bottom: 3px solid #75b051;
}
.basket .tabs > label {
  margin-right: 32px;
}

.basket__body {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
.basket .tabs-block {
  font-size: 14px;
  line-height: 17px;
  color: #606060;
}
.basket .tabs-block p {
  font-size: 18px;
  line-height: 22px;
}

.sertf {
  display: flex;
  column-gap: 10px;
}
.instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction p {
  font-size: 14px !important;
}
.basket .tabs-block .sertf img {
  /* padding-right: 25px; */
  cursor: pointer;
}
.basket__about {
  flex: 1 1 40%;
  max-width: 100%;
}
.basket__about h2 {
  font-size: 18px;
  line-height: 22px;
  color: #606060;
  padding-bottom: 20px;
}
.basket__about div {
  font-size: 16px;
  line-height: 17px;
  color: #606060;
}
/* End of tabs */

.recommendation {
  padding-top: 145px;
}
.recommendation__title {
  padding: 50px 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #2b2b2b;
}
/* .recommendation__body {
  display: flex;
  justify-content: center;
  column-gap: 60px;
} */

.animation {
  animation: wish 0.5s linear;
}

@keyframes wish {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.4);
    /* opacity: 0; */
  }
  75% {
    transform: scale(0.1);
    /* opacity: 0; */
  }
  100% {
    transform: scale(0);
  }
}

@media (max-width: 1035px) {
  .main .wrapper {
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
  .gallery {
    padding: 20px 30px;
  }
  .recommendation {
    padding-top: 50px;
  }
  .main .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    width: 100%;
  }
  .products__image {
    flex: 1 1 50%;
  }
  .main .wrapper .right__cnt {
    flex: 1 1 auto;
    align-items: center;
  }

  .main .wrapper .right__cnt h2 {
    text-align: center;
  }

  .main .wrapper .right__cnt .right__basket {
    align-items: center;
  }

  .main .wrapper .right__cnt .right__basket .order {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .products__image,
  .right__cnt {
    width: 100%;
  }
  .basket .tabs {
    display: flex;
  }
  .basket .tabs > label {
    margin-right: 15px;
  }
}

@media (max-width: 320px) {
  .basket .tabs > label {
    margin-right: 0;
  }
}
</style>
