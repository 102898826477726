var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"product d-flex flex-column justify-content-center"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"products__image"},[_c('ul',{staticClass:"breadcrumb"},[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Главная /")])],1),_c('li',[_c('router-link',{attrs:{"to":"/products"}},[_vm._v("Продукция /")])],1),_c('li',[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('ProductGallery',{attrs:{"image":_vm.product.image,"images":_vm.product.images}})],1),_c('div',{staticClass:"right__cnt"},[_c('p',[_vm._v("Артикул: "+_vm._s(_vm.product.sku))]),_c('h2',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.product.name))]),_c('h4',[_vm._v(" "+_vm._s(_vm.product.meta_description)+" ")]),_c('div',{staticClass:"right__price my-4"},[_c('span',{class:[{old: _vm.product.special}]},[_vm._v(_vm._s(_vm.product.price_formated))]),(_vm.product.special)?_c('span',{staticClass:"special"},[_vm._v(_vm._s(_vm.product.special_formated))]):_vm._e()]),_c('div',{staticClass:"natural right__basket"},[(_vm.getCountInPakage)?_c('h5',[_vm._v("Объем: "+_vm._s(_vm.getCountInPakage)+" шт.")]):_vm._e(),_c('div',{staticClass:"calc"},[_c('h5',[_vm._v("Количество:")]),_c('span',{staticClass:"bordered",on:{"click":_vm.decrementCount}},[_vm._v(" - ")]),_c('div',[_vm._v(_vm._s(_vm.count))]),_c('span',{staticClass:"bordered",on:{"click":_vm.incrementCount}},[_vm._v(" + ")])]),_c('div',{staticClass:"stock-status"},[_c('p',{style:({
                  color: [
                    _vm.isInStock
                      ? 'red'
                      : '#49C50E' ],
                })},[_vm._v(" "+_vm._s(_vm.product.stock_status)+" ")])]),_vm._m(0),_c('div',{staticClass:"order"},[_c('button',{staticClass:"order__add",on:{"click":_vm.addToCart}},[_vm._v(" Добавить в корзину ")]),_c('button',{staticClass:"order__wish",class:{ animation: _vm.addedToWish },attrs:{"disabled":!_vm.isAuthenticated},on:{"click":function($event){return _vm.addToWish(_vm.product.id)}}},[_c('img',{attrs:{"src":require("@/assets/img/likes.svg"),"alt":""}})]),_c('button',{staticClass:"order__fast",on:{"click":_vm.sendToWhatsUp}},[_vm._v(" Получить консультацию специалиста ")]),_c('a',{staticClass:"buy-credit",attrs:{"href":_vm.product.location}},[_vm._v(" Купить в кредит ")])])])])])])]),_c('section',{staticClass:"basket"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"basket__body"},[_c('div',{staticClass:"basket__about"},[_c('h2',[_vm._v("Описание:")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.description)}})]),_c('div',{staticClass:"tabs-block"},[_c('div',{staticClass:"tabs"},[_c('input',{attrs:{"type":"radio","name":"tab-btn","id":"tab-btn-1","value":"","checked":""}}),_c('label',{attrs:{"for":"tab-btn-1"}},[_vm._v("Состав:")]),_c('input',{attrs:{"type":"radio","name":"tab-btn","id":"tab-btn-2","value":""}}),_c('label',{attrs:{"for":"tab-btn-2"}},[_vm._v("Применение:")]),_c('input',{attrs:{"type":"radio","name":"tab-btn","id":"tab-btn-3","value":""}}),_c('label',{attrs:{"for":"tab-btn-3"}},[_vm._v("Отзывы ("+_vm._s(_vm.totalReviews)+")")]),_c('div',{attrs:{"id":"content-1"},domProps:{"innerHTML":_vm._s(_vm.product.description_sostav)}}),_c('div',{attrs:{"id":"content-2"},domProps:{"innerHTML":_vm._s(_vm.product.description_priminen)}}),_vm._l((_vm.getReviews),function(review){return _c('div',{key:review.author,attrs:{"id":"content-3"}},[_c('h4',[_vm._v(_vm._s(review.author))]),_c('p',{domProps:{"innerHTML":_vm._s(review.text)}})])})],2),(
              (_vm.product.sertificate && _vm.product.sertificate.length) ||
              (_vm.product.instruction && _vm.product.instruction.length)
            )?_c('div',{staticClass:"sertf"},[_c('p',[_vm._v("Инструкции и сертификаты:")]),_vm._l((_vm.product.sertificate),function(certificate,idx){return _c('img',{key:idx + 10,attrs:{"src":certificate,"alt":"sertificate"},on:{"click":_vm.showSertificate}})}),_vm._l((_vm.product.instruction),function(manual,idx){return _c('a',{key:idx,attrs:{"href":manual,"target":"_blank"}},[_vm._m(1,true)])})],2):_vm._e()])])])]),(_vm.relatedProducts && _vm.relatedProducts.length)?_c('section',{staticClass:"recommendation"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"recommendation__title"},[_vm._v("ВАМ ТАКЖЕ МОЖЕТ ПОНРАВИТЬСЯ")]),_c('div',{staticClass:"recommendation__body"},[_c('Carousel',{attrs:{"related":"","products":_vm.relatedProducts}})],1)])]):_vm._e(),_c('ImageCard',{attrs:{"visible":_vm.showImage},on:{"close":function($event){return _vm.exit('image')}}}),_c('OneClickModal',{attrs:{"visible":_vm.showOneClick,"product":_vm.product},on:{"close":_vm.exit}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"property__image my-5"},[_c('img',{attrs:{"src":require("@/assets/img/prod_prop1.svg")}}),_c('img',{staticClass:"mx-4",attrs:{"src":require("@/assets/img/prod_prop2.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/prod_prop3.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instruction"},[_c('img',{attrs:{"src":require("../../assets/img/pdf.svg"),"width":"80","height":"80","alt":"instruction.pdf"}}),_c('p',[_vm._v("Инструкция.pdf")])])}]

export { render, staticRenderFns }